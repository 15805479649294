<template>
  <div class="row m-0 justify-content-center">
    <div class="col-12 text-center">
      <span>Donation Amount: <b>{{total}}</b></span>
    </div>
    <div class="col-11 my-4">
      <div class="letter_spacing green-text-dark bold text-center">
        PAYMENT DETAILS
      </div>
      <StripeSection
        @stripeContinue="stripeContinue"
        @recurringComplete="recurringComplete"
        v-model:isLoading="isLoadingValue"
        :hidePayButton="true"
        :checkCard="checkCard"
        :turnstileReturn="turnstileReturn"
        :isRecurringPayment="isRecurringPayment"
      />
    </div>
    <transition name="fade">
      <TurnstilePopup v-if="isTurnstile" @close="closeTurnstile" @verify="verifyTurnstile"/>
    </transition>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    StripeSection: defineAsyncComponent(() => import('@/views/stripe/StripeSection.vue')),
    TurnstilePopup: defineAsyncComponent(() => import('@/views/TurnstilePopup.vue'))
  },
  props: ['isLoading', 'checkCard', 'total', 'donationSaved'],
  emits: ['update:isLoading', 'stripeContinue'],
  name: 'Recurring PaymentSection',
  data () {
    return {
      isRecurringPayment: null,
      isTurnstile: false,
      turnstileReturn: null
    }
  },
  watch: {
    async donationSaved () {
      if (this.donationSaved) {
        await this.checkTurnstile()
      }
    }
  },
  computed: {
    isLoadingValue: {
      get () {
        return this.isLoading
      },
      set (val) {
        this.$emit('update:isLoading', val)
      }
    }
  },
  methods: {
    recurringComplete (val) {
      console.log('recurringComplete')
      this.$router.push(`/recurringpaymentcompleteUk/${this.isRecurringPayment.subscriptionReference}/${this.isRecurringPayment.recurringDonationID}`)
    },
    stripeContinue () {
      console.log('stripeContinue')
      this.$emit('stripeContinue')
    },
    closeTurnstile () {
      this.turnstileReturn = null
      this.isTurnstile = false
    },
    async verifyTurnstile (val) {
      this.turnstileReturn = val
      this.isTurnstile = false
      if (this.turnstileReturn) {
        await this.startPaymentProcess()
      }
    },
    checkTurnstile () {
      this.isTurnstile = true
    },
    async startPaymentProcess () {
      this.isLoadingValue = true
      if (this.donationSaved.data) {
        this.isRecurringPayment = this.donationSaved.data
      }
    }
  }
}
</script>
